import { convertNFTThumbnail } from "helpers/formatters";
import React from "react";
import { useEffect, useState } from "react";
import { Blurhash } from "react-blurhash";
import {
  LazyLoadImage,
} from "react-lazy-load-image-component";
import styled from "styled-components";
import { ANTHROAddress, HUMANMUSICAddress } from "utils/constant";

const blurhash = require('utils/blurhash.json');
const { human, anthro } = blurhash;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  video {
    width: 100%;
    height: auto;
  }
`;

const StyledBlurhash = styled(Blurhash)`
  z-index: 20;
  position: absolute !important;
  top: 0;
  left: 0;
`;

const BlurHashLazyLoading = (props) => {
  const { tokenAddress, tokenId, src, autoplay = false } = props;

  const [isLoaded, setLoaded] = useState(false);
  const [isLoadStarted, setLoadStarted] = useState(false);

  useEffect(() => {
    return () => {
      setLoaded(false);
      setLoadStarted(false);
    }
  }, [])

  const handleLoad = () => {
    setLoaded(true);
  };

  const handleLoadStarted = () => {
    setLoadStarted(true);
  };

  return (
    <ImageWrapper>
      {
        tokenAddress !== HUMANMUSICAddress
          ? <React.Fragment>
            <LazyLoadImage
              src={convertNFTThumbnail(tokenAddress, tokenId)}
              style={{ objectFit: "contain" }}
              height={'100%'}
              width={'100%'}
              onLoad={handleLoad}
              beforeLoad={handleLoadStarted}
            />
            {!isLoaded && (
              <StyledBlurhash
                hash={tokenAddress === ANTHROAddress ? anthro[tokenId] : human[tokenId]}
                width={'100%'}
                height={'100%'}
                resolutionX={32}
                resolutionY={32}
                punch={1}
              />
            )}
          </React.Fragment>
          : <video
            autoPlay={autoplay}
            playsInline={true}
            muted
            loop={false}
            preload="auto"
            controls={true}
          >
            <source src={src} type="video/mp4" />
          </video>
      }
    </ImageWrapper>
  );
}

export default BlurHashLazyLoading;
