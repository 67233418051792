import moment from "moment";
import { ANTHROAddress, HUMANAddress, s0uImageFallback, s0uProperties, S0U_THUMBNAIL } from "utils/constant";

export const n6 = new Intl.NumberFormat("en-us", {
  style: "decimal",
  minimumFractionDigits: 0,
  maximumFractionDigits: 6,
});
export const n4 = new Intl.NumberFormat("en-us", {
  style: "decimal",
  minimumFractionDigits: 0,
  maximumFractionDigits: 4,
});

export const c2 = new Intl.NumberFormat("en-us", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

/**
 * Returns a string of form "abc...xyz"
 * @param {string} str string to string
 * @param {number} n number of chars to keep at front/end
 * @returns {string}
 */
export const getEllipsisTxt = (str, n = 6) => {
  if (str) {
    return `${str.slice(0, n)}...${str.slice(str.length - n)}`;
  }
  return "";
};

export const convertProperties = (property) => {
  let response;
  for (let [key, value] of Object.entries(s0uProperties)) {
    if (property.includes(key)) {
      response = value;
    }
  }
  return response;
};

export const convertNFTThumbnail = (tokenAddress, tokenId) => {
  switch (tokenAddress) {
    case ANTHROAddress:
      return `https://ipfs.moralis.io:2053/ipfs/${S0U_THUMBNAIL[tokenAddress]}/${tokenId}.png`;
    case HUMANAddress:
      return `https://ipfs.moralis.io:2053/ipfs/${S0U_THUMBNAIL[tokenAddress]}/${tokenId}.png`;
    default:
      return s0uImageFallback[tokenAddress];
  }
}

export const tokenValue = (value, decimals) => (decimals ? value / Math.pow(10, decimals) : value);

export const updatedDuration = (updatedDate) => (parseInt(moment.duration(moment(new Date()).diff(moment(updatedDate))).as('days')));

/**
 * Return a formatted string with the symbol at the end
 * @param {number} value integer value
 * @param {number} decimals number of decimals
 * @param {string} symbol token symbol
 * @returns {string}
 */
export const tokenValueTxt = (value, decimals, symbol) => `${n4.format(tokenValue(value, decimals))} ${symbol}`;
