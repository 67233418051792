import { useEffect, useState } from "react";

const useTokenProperties = (tokenAddress, tokenId, Moralis, chain) => {
  const [transactions, setTransactions] = useState();

  useEffect(() => {
    if (tokenAddress && tokenId) {
      fetchTokenTransaction();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenAddress, tokenId]);

  const fetchTokenTransaction = async () => {
    try {
      const options = {
        chain,
        address: tokenAddress,
        tokenId: tokenId,
        limit: 50
      };
      const {jsonResponse: response} = await Moralis.EvmApi.nft.getNFTTransfers(options);
      if (response?.result) {
        setTransactions(response?.result)
        return response?.result;
      }
      return [];
    } catch (error) {
      console.log('error: ', error);
      return [];
    }
  };
  return { fetchTokenTransaction, transactions };
};

export default useTokenProperties;
