import {
  Button,
  Card,
  Badge,
  Alert,
  Tooltip
} from "antd";
import { convertProperties, updatedDuration } from "helpers/formatters";
import useTokenProperties from "hooks/useTokenProperties";
import { StakingAddress, s0uMoralis, s0uProperties } from "utils/constant";
import moment from "moment";
import BlurHashLazyLoading from "./LazyLoadImage/BlurHashLazyLoading";
import styled from "styled-components";
import { useEffect } from "react";

const NFTScore = styled.div`
  display: flex;
  padding-top: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
`;

const PropertiesPage = ({ nft = {}, collection, tokenAddress, Moralis, chain }) => {
  const { transactions = [] } = useTokenProperties(tokenAddress, nft.tokenId, Moralis, chain);

  const ownedTxs = transactions.filter(item => item.from_address !== StakingAddress && item.to_address !== StakingAddress) || [];
  const ownedTx = ownedTxs[0] || {};
  const { block_timestamp: ownedTime } = ownedTx;

  const durationOwned = parseInt(moment.duration(moment(new Date()).diff(moment(ownedTime))).as('days'));
  const characterScore = 1000 + durationOwned;

  const increaseProperties = {
    BACKGROUND: s0uProperties.BACKGROUND,
    BODY: s0uProperties.BODY
  };

  const updateProperties = async () => {
    console.log("characterScore:" , characterScore);
  }

  const additionalScore = (property) => {
    let response = 0;
    for (let [key] of Object.entries(increaseProperties)) {
      if (property.includes(key)) {
        response = durationOwned;
      }
    }
    return response;
  };

  useEffect(() => {
    if (characterScore > 0 && characterScore !== nft.score) {
      updateProperties();
    }
  }, [characterScore])

  return (
    <>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap"
        }}
      >
        <div style={{ fontSize: "18px", flex: '1 0 50%', minWidth: 350 }}>
          <Badge.Ribbon
            text={`Rank #${nft.rank}`}
          >
            <BlurHashLazyLoading tokenAddress={tokenAddress} tokenId={nft.tokenId} src={nft.image} autoplay={true} />
          </Badge.Ribbon>
          <NFTScore>
            <div>{`Character Score `}
              <span>{`${characterScore
                ? characterScore
                : nft.score
                  ? (parseInt(nft.score) + updatedDuration(nft.updatedAt))
                  : 0
                }`}</span>
            </div>
            <div>{`Rarity Score `}
              <span>{`${parseInt(nft.rarity)}`}</span>
            </div>
          </NFTScore>
        </div>
        <Card
          title={<div style={{
            display: 'flex',
            justifyContent: 'center'
          }}>
            <span>{`S0U ${collection} #${nft.tokenId}`}</span>
          </div>}
          bordered={false}
          style={{ flex: '1 0 50%', minWidth: 350 }}
        >
          {nft?.attributes?.filter(item => !!item.value && item.trait_type !== "TraitCount").map((e) => {
            return (
              <div key={`attribute-${e.trait_type}`}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontWeight: "bold",
                  }}
                >
                  <span style={{ color: "#fff" }}>{e.trait_type}</span>
                  {
                    convertProperties(e.trait_type)
                      ? <span style={{ whiteSpace: 'nowrap' }}>
                        <span>{`${convertProperties(e.trait_type)}`}</span>
                        <Tooltip placement="topRight" title={false}>
                          <span
                            style={{ color: "#0de6ff", paddingRight: "4%" }}
                          >{` +${e.trait_type === "QUEEN" ? 9999 : (parseInt(e.rarityScore) + additionalScore(e.trait_type))}`}
                          </span>
                        </Tooltip>
                      </span>
                      : null
                  }
                </div>
                <Alert
                  style={{
                    padding: "2px 2px 2px 12px",
                    width: "98%",
                    margin: "0px auto 5px",
                    fontSize: "14px",
                  }}
                  message={e.value ? e.value : "<null>"}
                  type="info"
                  action={
                    <Button
                      size="small"
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        width: "60px",
                        background: 'transparent',
                        border: 'none',
                        color: '#fff',
                      }}
                    >
                      {e.trait_type === "TraitCount" ?
                        ((8 * (10000 / e.rarityScore)).toFixed(0)) :  //Only use this if rarity generator adjusted to 8x traitcount
                        (`${(100 / e.rarityScore).toFixed(2)}%`)        //Also must be adjusted for collections with +- 10000 NFTs
                      }
                    </Button>
                  }
                />
              </div>
            );
          })}
        </Card>
      </div>
    </>
  );
}

export default PropertiesPage;
