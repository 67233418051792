import Moralis from "moralis";
import { useCallback, useEffect, useState } from "react";

const apiKey = process.env.REACT_APP_MORALIS_API_KEY;
const chain = process.env.REACT_APP_CHAIN_ID;

const useMoralis = () => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [isInitializing, setIsInitializing] = useState(false);

  const _initialize = useCallback(
    async () => {
      if (isInitialized) {
        return;
      }

      if (!apiKey) {
        return;
      }

      setIsInitializing(true);
      await Moralis.start({ apiKey });
      setIsInitializing(false);
      setIsInitialized(true);
    }, []);

  useEffect(() => {
    console.log("useMoralis: ", isInitialized, Moralis);
    if (isInitialized) {
      return;
    } else {
      _initialize();
    }
  }, []);

  return {
    isInitialized,
    isInitializing,
    Moralis,
    chain
  };
};

export default useMoralis;