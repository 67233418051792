import anthro from './anthro-rarity.json';
import human from './human-rarity.json';
import humanmusic from './humanmusic-rarity.json';

export const ANTHROAddress = '0xeE5f115811d18a1c5D95457c83ba531Ce0c92f06';
export const HUMANAddress = '0xa5c5198c6CE1611f1e998cf681450F8b9E599255';
export const HUMANMUSICAddress = '0x021CD12F07d12B1fe1E53B057e1D38553bCc4D72';
export const StakingAddress = '0x08ae17ac1cef9308ecd2566c8547c069e3844079';
export const ZeroAddress = '0x0000000000000000000000000000000000000000';

export const s0uImageFallback = {
  ANTHRO: 'https://gateway.ipfs.io/ipfs/Qmez8QrDYPxCHwaoUPEZVzDkz47L6LK47kVwYy3537YvPi/hidden.png',
  HUMAN: 'https://gateway.ipfs.io/ipfs/QmVWkBbavGtiufwwd8iHMaq5KBUmJ4q7iLw6H1EJHt17hC/hidden.jpg',
  HUMANMUSIC: 'https://gateway.ipfs.io/ipfs/QmVWkBbavGtiufwwd8iHMaq5KBUmJ4q7iLw6H1EJHt17hC/hidden.jpg',
  [ANTHROAddress]: 'https://gateway.ipfs.io/ipfs/Qmez8QrDYPxCHwaoUPEZVzDkz47L6LK47kVwYy3537YvPi/hidden.png',
  [HUMANAddress]: 'https://gateway.ipfs.io/ipfs/QmVWkBbavGtiufwwd8iHMaq5KBUmJ4q7iLw6H1EJHt17hC/hidden.jpg',
  [HUMANMUSICAddress]: 'https://gateway.ipfs.io/ipfs/QmVWkBbavGtiufwwd8iHMaq5KBUmJ4q7iLw6H1EJHt17hC/hidden.jpg',
}

export const s0uNFTs = {
  ANTHRO: 'ANTHRO',
  HUMAN: 'HUMAN',
  HUMANMUSIC: 'HUMANMUSIC',
  [ANTHROAddress]: 'ANTHRO',
  [HUMANAddress]: 'HUMAN',
  [HUMANMUSICAddress]: 'HUMANMUSIC'
}

export const s0uMoralis = {
  ANTHRO: 'S0UANTHRO',
  HUMAN: 'S0UHUMAN',
  HUMANMUSIC: 'HUMANMUSIC',
  [ANTHROAddress]: 'S0UANTHRO',
  [HUMANAddress]: 'S0UHUMAN',
  [HUMANMUSICAddress]: 'HUMANMUSIC'
}

export const s0uRarity = {
  ANTHRO: anthro,
  HUMAN: human,
  HUMANMUSIC: humanmusic,
  [ANTHROAddress]: anthro,
  [HUMANAddress]: human,
  [HUMANMUSICAddress]: humanmusic
}

export const s0uProperties = {
  ACCESSORIES: 'Attack (ATK)',
  ARMOR: 'Defense (DEF)',
  ARMS: 'Date Value',
  BACKGROUND: 'Passion',
  BODY: 'Passion Value',
  // CLOTHES: 'Occupation',
  // EYES: 'Attribute',
  HAIR: 'Magic Power'
}

export const s0uVaults = {
  ANTHRO: 0,
  HUMAN: 1,
  HUMANMUSIC: 2,
  [ANTHROAddress]: 0,
  [HUMANAddress]: 1,
  [HUMANMUSICAddress]: 2
}

export const NETWORK = {
  MAINNET: '0x1',
  SEPOLIA: '0xaa36a7'
}

export const S0U_THUMBNAIL = {
  ANTHRO: 'Qmegx6fNNmMgt6RxnReGP9fxu2v3AtFNQXxXAooCNa735U',
  HUMAN: 'QmQt8fcqGCQtpKPZPYTrYyidfbXdujVcf9cGVp86LGQmbU',
  HUMANMUSIC: 'HUMANMUSIC',
  [ANTHROAddress]: 'Qmegx6fNNmMgt6RxnReGP9fxu2v3AtFNQXxXAooCNa735U',
  [HUMANAddress]: 'QmQt8fcqGCQtpKPZPYTrYyidfbXdujVcf9cGVp86LGQmbU',
  [HUMANMUSICAddress]: 'HUMANMUSIC'
}