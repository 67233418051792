import {
  Button,
  Divider,
  Modal,
  Input,
  Select,
} from "antd";
import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { ANTHROAddress, HUMANAddress, HUMANMUSICAddress, NETWORK, s0uMoralis, s0uVaults, s0uRarity } from "utils/constant";
import PropertiesPage from "./Properties";
import { useMedia } from 'react-use';
import { utils as ethersUtils } from "ethers";
import { useStaking } from "hooks/useStaking";
import BlurHashLazyLoading from "./LazyLoadImage/BlurHashLazyLoading";
import React from "react";
import { useMutation } from "react-query";
import { SwapOutlined } from "@ant-design/icons";
import { useWallet } from "hooks/useWallet";
import { hexValue } from "ethers/lib/utils";

const { Search } = Input;
const { Option } = Select;
const { getAddress } = ethersUtils;
const s0uCollections = [ANTHROAddress, HUMANAddress, HUMANMUSICAddress];
const PAGE_SIZE = 18;

const CollectionPage = props => {
  const { moralis: Moralis, chain } = props;

  const { tokenAddress } = useParams();
  const below768 = useMedia('(max-width: 768px)');
  const history = useHistory();
  const selectBoxRef = useRef();

  const styles = {
    NFTs: {
      display: "flex",
      flexWrap: "wrap",
      WebkitBoxPack: "start",
      justifyContent: "flex-start",
      margin: "0px auto 20px",
      width: '100%',
      maxWidth: "1200px",
      gap: "10px",
    },
    nftBoxGrid: {
      margin: '0 auto',
      maxWidth: 1200,
      padding: '1rem',
      width: '100%',
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(160px, 300px))',
      gridGap: 8,
      justifyContent: 'center'
    },
    nftBox: {
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 16,
      border: '1px solid #ccc',
      minHeight: 250,
      overflow: 'hidden',
      background: '#000',
      cursor: 'pointer',
      '& h4': {
        margin: '1rem 0.5rem',
        textAlign: 'center'
      }
    },
    tokenDescription: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '16px 8px'
    },
    rightDesc: {
      textAlign: 'end'
    },
    tokenRank: {
      fontWeight: 700
    },
    propertiesModal: {
      width: '90vw',
      maxWidth: 1000
    },
    header: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      gap: 16,
      justifyContent: below768 ? 'space-evenly' : 'space-between'
    },
    wrapSelectBox: {
      fontSize: "26px",
      fontWeight: "bold",
      color: "#fff"
    },
    selectBox: {
      fontSize: below768
        ? tokenAddress === HUMANMUSICAddress
          ? "1rem"
          : "1.2rem"
        : "2rem",
      fontWeight: "bold",
      color: "#fff",
      background: 'transparent',
      whiteSpace: 'nowrap',
      border: 'none',
      width: 'fit-content',
      maxWidth: '100%'
    }
  };

  const searchRef = useRef(null);

  const collections = [
    { tokenAddress: ANTHROAddress, name: 'ANTHRO', label: 'Singularity 0 Universe ANTHRO' },
    { tokenAddress: HUMANAddress, name: 'HUMAN', label: 'Singularity 0 Universe HUMAN' },
    { tokenAddress: HUMANMUSICAddress, name: 'HUMAN MUSIC', label: 'Singularity 0 Universe HUMAN MUSIC' },
  ];

  const [visibility, setVisibility] = useState(false);

  const [NFTBalances, setNFTBalances] = useState();
  const [searchNFT, setSearchNFT] = useState();
  const [collection, setCollection] = useState();
  const [nft, setNft] = useState({});

  const fetchMyNFTs = async () => {
    const stakedTokenIds = await getVaults();
    const myTokenIds = await fetchMyNFTIds();

    // const dbNFTs = Moralis.Object.extend(s0uMoralis[tokenAddress]);
    // const query = new Moralis.Query(dbNFTs);
    const tokenIds = [...myTokenIds, ...stakedTokenIds];
    // query.containedIn("tokenId", tokenIds);
    // const walletNFTs = query.limit(1000);
    // const results = await walletNFTs.find();
    const results = await s0uRarity[tokenAddress].filter(item => tokenIds.includes(item.tokenId));
    console.log("fetchMyNFTs: ", tokenIds, results);

    return results;
  };

  const { address: account, network = {}, switchNetwork, connectWallet } = useWallet();
  const chainId = hexValue(network.chainId || 1);

  const { data: myNFTs, mutate } = useMutation(fetchMyNFTs)

  const stakingContract = useStaking();

  const isMainnet = chainId === NETWORK.MAINNET;

  useEffect(() => {
    if (tokenAddress && !s0uCollections.includes(tokenAddress)) {
      history.push('/');
    }
  }, [tokenAddress])

  useEffect(() => {
    if (tokenAddress) {
      collectionChanged(s0uMoralis[tokenAddress]);
    }
  }, [tokenAddress])

  useEffect(() => {
    if (account && stakingContract && isMainnet && s0uCollections.includes(tokenAddress)) {
      mutate();
    }
  }, [account, stakingContract, chainId, tokenAddress])

  const fetchMyNFTIds = async () => {
    try {
      const options = {
        chain,
        address: account,
        tokenAddresses: [tokenAddress],
      };
      const {jsonResponse: response} = await Moralis.EvmApi.nft.getWalletNFTs(options);

      const tokenIds = response?.result?.map(item => item.token_id);

      return tokenIds;
    } catch (error) {
      console.log('error: ', error);
      return [];
    }
  }

  const handleChangeCollection = async () => {
    setNFTBalances(s0uRarity[tokenAddress].slice(0, PAGE_SIZE));
  };

  const handleSelectToken = async (num, col) => {
    if (num && col) {
      console.log("handleSelectToken: ", num, col)
      // const dbNFTs = Moralis.Object.extend(col);
      // const query = new Moralis.Query(dbNFTs);
      // query.equalTo("tokenId", num);
      // let selectedNFT = await query.first();
      setNft(num);
      setVisibility(true);
    }
  };

  const collectionChanged = async (col) => {
    setCollection(col);
    handleChangeCollection(col);
  };

  const addToNFTs = async (col) => {
    // const dbNFTs = Moralis.Object.extend(col);
    // const query = new Moralis.Query(dbNFTs);
    // query.ascending("rank");
    // query.limit(18);
    // const topNFTs = query.skip(NFTBalances.length);
    // const results = await topNFTs.find();
    // setNFTBalances(NFTBalances.concat(results));
    setNFTBalances(s0uRarity[tokenAddress].slice(0, PAGE_SIZE + NFTBalances.length));
  }

  const onSearchNFT = async () => {
    const tokenId = searchRef?.current?.input?.value;
    if (tokenId) {
      // const dbNFTs = Moralis.Object.extend(s0uMoralis[tokenAddress]);
      // const query = new Moralis.Query(dbNFTs);

      // query.equalTo("tokenId", tokenId);
      const results = await s0uRarity[tokenAddress].find(item => item.tokenId === tokenId);
      setSearchNFT(results);
    } else {
      setSearchNFT(null);
    }
  }

  const getVaults = async () => {
    if (stakingContract) {
      try {
        const response = await stakingContract["tokensOfOwner(address,uint256)"](getAddress(account), s0uVaults[tokenAddress]);
        const stakedTokenIds = response.map(item => `${item.toNumber()}`);
        return stakedTokenIds;
      } catch (error) {
        console.log('error: ', error);
        return [];
      }
    }
  }

  const handleConnectWallet = () => {
    if (account && !isMainnet) {
      switchNetwork(NETWORK.MAINNET);
    } else {
      connectWallet();
    }
  }
  const closeProperties = () => {
    setVisibility(false);
  }

  const renderNFTBox = _nft => {
    const {tokenId, image, rank, rarity} = _nft;
    return (
    <div
      style={styles.nftBox}
      key={tokenId}
      onClick={() => handleSelectToken(_nft, collection)}
    >
      <BlurHashLazyLoading tokenAddress={tokenAddress} tokenId={tokenId} src={image} />
      <div style={styles.tokenDescription}>
        <div>
          <div>{`Rank #${rank}`}</div>
          {/* <div>{`Character Score ${attributes.score ? (parseInt(attributes.score) + updatedDuration(attributes.updatedAt)) : 0}`}</div> */}
        </div>
        <div style={styles.rightDesc}>
          <div>{`#${tokenId}`}</div>
          <div>{`Rarity Score ${parseInt(rarity)}`}</div>
        </div>
      </div>
    </div>
  )};
  const handleSelectCollection = (tokenAddress) => {
    history.push(`/collection/${tokenAddress}`);
  };

  return (
    <div style={{
      width: '100%',
      maxWidth: "1200px"
    }}>
      <div style={styles.header}>
        <div
          style={styles.wrapSelectBox}
        >
          <Select
            showSearch
            style={styles.selectBox}
            placeholder="Select Collection"
            value={tokenAddress}
            defaultOpen={false}
            ref={selectBoxRef}
            showAction="focus"
            onChange={(e) => handleSelectCollection(e)}
          >
            {
              collections.map(item => (
                <Option key={item.tokenAddress} value={item.tokenAddress}>{item.label}</Option>
              ))
            }
          </Select>
          <SwapOutlined onClick={() => selectBoxRef.current.focus()} />
        </div>
        <Search
          ref={searchRef}
          placeholder="Enter your NFT ID"
          allowClear
          enterButton="Search"
          size="large"
          onSearch={onSearchNFT}
          style={{ width: 248 }}
        />
      </div>
      {
        !searchNFT
          ? <React.Fragment>
            <Divider />
            <div
              style={{
                fontSize: "26px",
                fontWeight: "bold",
                color: "#fff",
                whiteSpace: 'nowrap'
              }}
            >
              {`My NFTs`}
            </div>
            {account && isMainnet
              ? <div style={styles.nftBoxGrid}>
                {
                  myNFTs?.map(nft => renderNFTBox(nft))
                }
              </div>
              : <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  className="mainButton"
                  onClick={handleConnectWallet}
                  type="primary"
                >
                  {account && !isMainnet ? 'Switch to mainnet' : 'Connect Wallet'}
                </Button>
              </div>}
          </React.Fragment>
          : null
      }
      <Divider />
      <div style={styles.nftBoxGrid}>
        {
          searchNFT
            ? renderNFTBox(searchNFT)
            : NFTBalances?.map(nft => renderNFTBox(nft))
        }
      </div>
      {NFTBalances && !searchNFT && <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button className="mainButton" onClick={() => addToNFTs(collection)} type="primary">Load More</Button>
      </div>}
      {
        visibility
          ? <Modal visible={visibility} onCancel={closeProperties} footer={null} width={1000}>
            <PropertiesPage 
              nft={nft} 
              collection={collection} 
              tokenAddress={tokenAddress} 
              Moralis={Moralis}
              chain={chain}
            />
          </Modal>
          : null
      }
    </div>
  );
}

export default CollectionPage;
