import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { Button, Image, Layout } from "antd";
import { QueryClient, QueryClientProvider } from 'react-query'

import "antd/dist/antd.css";
import "./style.css";
import QuickStart from "components/QuickStart";
import { ANTHROAddress, HUMANAddress, HUMANMUSICAddress, NETWORK } from "utils/constant";
import { useMedia } from "react-use";

import ANTHROImage from 'assets/images/anthro.png';
import HUMANImage from 'assets/images/human.jpg';
import { hexValue } from "ethers/lib/utils";
import { useWallet } from "hooks/useWallet";
import useMoralis from "hooks/useMoralis";

const { Header } = Layout;
const MAINNET = process.env.REACT_APP_CHAIN_ID;

const App = () => {
  const history = useHistory();

  const { address: account, network = {}, switchNetwork, connectWallet } = useWallet();
  const chainId = hexValue(network.chainId || 1);

  const queryClient = new QueryClient();
  const isMainnet = chainId === MAINNET;
  const below768 = useMedia('(max-width: 768px)');

  const { Moralis, chain } = useMoralis();

  const styles = {
    content: {
      display: "flex",
      minHeight: 'calc(100vh - 300px)',
      alignItems: "center",
      flexDirection: "column",
      fontFamily: "Roboto, sans-serif",
      color: "#fff",
      overflow: 'auto',
      background: 'transparent',
      padding: below768 ? 20 : 40,
      flex: '1 0 100%'
    },
    header: {
      position: "fixed",
      zIndex: 1,
      width: "100%",
      background: "#fff",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontFamily: "Roboto, sans-serif",
      borderBottom: "2px solid rgba(0, 0, 0, 0.06)",
      padding: "0 10px",
      boxShadow: "0 1px 10px rgb(151 164 175 / 10%)",
    },
    pageHeader: {
      backgroundImage: "url('./images/header.png')",
      width: '100%',
      backgroundSize: 'cover',
      height: 300,
      maxHeight: 'calc(100vw / 2.5)',
      backgroundPosition: '50% 50%',
      cursor: 'pointer'
    },
    headerRight: {
      display: "flex",
      gap: "20px",
      alignItems: "center",
      fontSize: "15px",
      fontWeight: "600",
    },
    nftBoxGrid: {
      margin: '0 auto',
      maxWidth: 1200,
      padding: '1rem',
      width: '100%',
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(auto, 15rem))',
      gridGap: 20,
      justifyContent: 'center'
    },
    nftBox: {
      borderRadius: 16,
      border: '1px solid #ccc',
      minHeight: 200,
      overflow: 'hidden',
      background: '#000',
      cursor: 'pointer',
      '& h4': {
        margin: '1rem 0.5rem',
        textAlign: 'center'
      }
    },
    wrapHome: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 0 100%',
      alignItems: 'center',
      justifyContent: 'center'
    },
    wrapLayout: {
      height: '100vh',
      overflow: 'auto',
      background: "url('./images/background.png')",
      backgroundSize: 'cover',
    },
    layoutStyle: {
      minHeight: "100vh",
      overflow: "auto",
      background: 'transparent'
    },
    description: {
      display: 'flex',
      justifyContent: 'center',
      padding: '16px 8px',
      fontWeight: 700,
      fontSize: '1.2rem'
    }
  };

  const collections = [
    { tokenAddress: ANTHROAddress, name: 'ANTHRO', label: 'S0U ANTHRO', image: ANTHROImage },
    { tokenAddress: HUMANAddress, name: 'HUMAN', label: 'S0U HUMAN', image: HUMANImage },
    { tokenAddress: HUMANMUSICAddress, name: 'HUMAN MUSIC', label: 'S0U HUMAN MUSIC', image: HUMANImage },
  ];

  const handleSelectCollection = (option) => {
    history.push(`/collection/${option.tokenAddress}`);
  }

  const handleConnectWallet = () => {
    if (account && !isMainnet) {
      switchNetwork(NETWORK.MAINNET);
    } else {
      connectWallet();
    }
  }

  return (
    <div style={styles.wrapLayout}>
      <Layout style={styles.layoutStyle}>
        <QueryClientProvider client={queryClient}>
          <Header style={styles.pageHeader} onClick={() => history.push('/')}>
            <span style={{ fontWeight: 700, textTransform: 'uppercase' }}>S0U NFT properties</span>
          </Header>

          <div style={{ ...styles.content }}>
            <Switch>
              <Route exact path="/home">
                <div style={styles.wrapHome}>
                  <div style={styles.nftBoxGrid}>
                    {collections?.map((item) => {
                      return (
                        <div
                          style={styles.nftBox}
                          key={item.tokenAddress}
                          onClick={() => handleSelectCollection(item)}
                        >
                          <Image
                            preview={false}
                            src={item.image || "error"}
                            alt={item.label}
                            style={{ objectFit: "contain" }}
                          />
                          <div style={styles.description}>
                            {item.label}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  {
                    !account || !isMainnet
                      ? <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                        <Button
                          className="mainButton"
                          onClick={handleConnectWallet}
                          type="primary"
                        >
                          {account && !isMainnet ? 'Switch to mainnet' : 'Connect Wallet'}
                        </Button>
                      </div>
                      : null
                  }
                </div>
              </Route>
              <Route path="/collection/:tokenAddress">
                <QuickStart moralis={Moralis} chain={chain}/>
              </Route>
              <Route path="/*">
                <Redirect to="/home" />
              </Route>
            </Switch>
          </div>
        </QueryClientProvider>
      </Layout>
    </div>
  );
};

export default App;
