import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import "./index.css";

const APP_ID = process.env.REACT_APP_MORALIS_APPLICATION_ID;

const Application = () => {
  return (
      <Router basename={`${process.env.REACT_APP_SUBDIRECTORY}`}>
        <App/>
      </Router>
  );
};

ReactDOM.render(
  // <React.StrictMode>
  <Application />,
  // </React.StrictMode>,
  document.getElementById("root")
);
